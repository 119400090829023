<template>
  <v-app>
    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div id="exportContent">
      <div class="page">
        <div align="center">
          <img width="150" height="150" src="ovec.png" />
          <br />
          <div class="head">
            เอกสารประกอบการพิจารณาคำร้องขอย้าย
          </div>
          <div class="head">
            ข้าราชการครูและบุคลากรทางการศึกษา
          </div>
          <div class="head">
            ตำแหน่ง ครู สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
          </div>

          <div class="head pa-1">
            ครั้งที่
            {{
              transference_personnels.time_ss +
                "/" +
                transference_personnels.year_ss
            }}
            <br />
            เอกสารชุดที่ 2 เอกสารประกอบการพิจารณาการย้ายองค์ประกอบที่ 7 (7.1)
          </div>

          <br />
          <div class="pa-5">
            <img
              v-if="transference_personnels.personnel_temporary_pic"
              width="300"
              height="300"
              style="border-radius: 8px"
              :src="
                '/HRvecfiles/' + transference_personnels.personnel_temporary_pic
              "
            />
            <img
              v-else
              width="200"
              src="/HRvecfiles/blank-human-image.png"
              style="border-radius: 30px"
            />
          </div>
          <div class="head">
            ชื่อ
            {{
              transference_personnels.title_s +
                transference_personnels.frist_name
            }}
            นามสกุล
            {{ transference_personnels.last_name }}
          </div>
          <div class="head">
            เลขที่ตำแหน่ง {{ transference_personnels.id_position }}
          </div>
          <div class="head">
            วิทยฐานะ {{ transference_personnels.rang_name || "-" }}
          </div>

          <br />
          <br />
          <div class="head">
            {{ transference_personnels.college_name }} จังหวัด{{
              transference_personnels.province_name
            }}
          </div>

          <div v-if="transference_personnels.collegeVei !== '0'">
            <div class="head">
              สถาบันอาชีวศึกษา{{ transference_personnels.veiVocalName }}
            </div>
          </div>
        </div>

        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 2 -->

        <div class="bold16">
          องค์ประกอบ/ตัวชี้วัดที่ 7 ภาระงานของข้าราชการครูและบุคลากรทางการศึกษา
        </div>
        <div class="bold16 text_j">
          7.1
          การแสดงภาระงานในการพัฒนาการจัดการเรียนการสอนตรงตามวิชาเอก/หรือตางกับความต้องการของสถานศึกษาที่รับย้าย
        </div>

        <table width="100%" class="text_j">
          <tr>
            <td width="10%"></td>
            <td width="90%">7.1.1 ความรู้ ในสาขาวิชาที่ย้ายไปดำรงตำแหน่ง</td>
          </tr>
        </table>

        <table width="100%" class="table text_j">
          <tr>
            <td width="15%" class="bold16 table" align="center">ลำดับ</td>
            <td width="55%" class="bold16 table" align="center">
              โครงการ/กิจกรรม
            </td>
            <td class="bold16 table" align="center">เอกสารประกอบการพิจารณา</td>
          </tr>
          <tr v-for="(item, index) in personnel_educations" :key="index">
            <td class="text-center table text_al">{{ index + 1 }}</td>
            <td class="pa-1 table text_al">
              ระดับ{{ item.education_level }} <br />
              ชื่อปริญญา {{ item.faculty_name }} <br />
              สาขา {{ item.branch_name }} <br />
              {{ item.academy_name }} ปีที่จบ {{ item.year_finish }}
            </td>
            <td class="pa-1 table text_al">
              สำเนา ก.พ.7 / ก.ค.ศ.16 <br />
              ภาคผนวก หน้า.................<br />
              สำเนาปริญญาบัตร <br />
              ภาคผนวก หน้า.................
            </td>
          </tr>
          <tr>
            <td colspan="3" class="pa-1 table text_al">
              <span class="bold16">หมายเหตุ</span> สำเนา ก.พ. 7 หรือ ก.ค.ศ. 16
              ที่เป็นปัจจุบัน โดยให้ผู้อำนวยการสถานศึกษา หัวหน้าส่วนราชการ
              หรือผู้ที่ได้รับมอบหมายงานทะเบียนประวัติลงลายมือชื่อรับรองสำเนาถูกต้องตามระเบียบงานสารบรรณ
              มีวุฒิ (ปริญญาตรี , ปริญญาโท , ปริญญาเอก)
              ที่ตรงกับวิชาเอกหรือที่เกี่ยวข้องกับการจัดการเรียนการ ทั้งนี้
              ให้ผู้ประสงค์ขอย้าย ลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารทุกฉบับ
              <br />
              แนบเอกสารและหลักฐานประกอบการพิจารณาแนบภาคผนวก ค
            </td>
          </tr>
        </table>

        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 3 -->

        <table width="100%" class="text_j">
          <tr>
            <td width="10%"></td>
            <td width="90%">
              7.1.2
              ความสามารถในการปฏิบัติหน้าที่ในวิชาเอกหรือที่เกี่ยวข้องกับการจัดการเรียนการสอนที่รับย้าย
            </td>
          </tr>
          <tr>
            <td colspan="2">
              หรือที่ผู้บังคับบัญชามอบหมาย
            </td>
          </tr>
        </table>

        <table width="100%" class="text_j table">
          <tr>
            <td width="15%" class="bold16 table" align="center">ลำดับ</td>
            <td width="55%" class="bold16 table" align="center">
              ชื่อโครงการ/กิจกรรมในสถานศึกษา
            </td>
            <td class="bold16 table" align="center">เอกสารประกอบการพิจารณา</td>
          </tr>
          <tr v-for="(item, index) in personnel_dutys" :key="index">
            <td class="text-center table text_al">{{ index + 1 }}</td>
            <td class="pa-1 table text_al">
              {{ item.personnel_dutyTopic }} <br />
            </td>
            <td class="pa-1 table text_al">
              คำสั่งที่ {{ item.personnel_dutyOrderID }}<br />
              ภาคผนวก หน้า................. <br />
            </td>
          </tr>
          <tr>
            <td colspan="3" class="pa-1 table">
              <span class="bold16">หมายเหตุ</span>
              โดยแนบคำสั่งของสถานศึกษาที่มอบหมายให้ปฏิบัติงาน ประกอบด้วย
              หน้าคำสั่ง หน้าที่มีชื่อผู้ยื่นย้าย และ
              หน้าสุดท้ายของคำสั่งที่มีลายมือชื่อของผู้ลงนามในคำสั่ง
              ย้อนหลังไม่เกิน 1 ปี
              นับถึงวันสุดท้ายของกำหนดการยื่นคำร้องขอย้ายประจำปี ทั้งนี้
              โดยให้ผู้ประสงค์ขอย้ายและผู้อำนวยการสถานศึกษา
              หรือผู้รักษาการในตำแหน่งผู้อำนวยการสถานศึกษาต้นสังกัด ลงลายมือชื่อ
              รับรองสำเนาถูกต้องของเอกสารทุกฉบับ ในหน้าหน้าคำสั่ง
              หน้าที่มีชื่อผู้ยื่นย้าย และ
              หน้าสุดท้ายของคำสั่งที่มีลายมือชื่อของผู้ลงนามในคำสั่ง <br />
              แนบเอกสารและหลักฐานประกอบการพิจารณาภาคผนวก ค
            </td>
          </tr>
        </table>

        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 10 -->

        <table width="100%" class="text_j">
          <tr>
            <td width="10%"></td>
            <td width="90%">
              7.1.3 การพัฒนาตนเอง
              โดยได้รับการอบรมสัมมนารวมทั้งศึกษาดูงานเพิ่มพูนความรู้ตนเอง
            </td>
          </tr>
        </table>

        <table width="100%" class="text_j table">
          <tr>
            <td width="10%" class="bold16 table" align="center">
              ลำดับที่
            </td>
            <td width="50%" class="bold16 table" align="center">
              ชื่อโครงการ
            </td>
            <td class="bold16 table" align="center">เอกสารประกอบการพิจารณา</td>
          </tr>
          <tr v-for="(item, index) in personnel_experiences" :key="index">
            <td class="pa-1 text-center text_al table" align="center">
              {{ index + 1 }}
            </td>
            <td class="pa-1 table text_al">
              {{ item.course_name }} ปี {{ item.pexperienceyear_s }}
            </td>
            <td class="pa-1 table text_al">
              ภาคผนวก หน้า.....................
            </td>
          </tr>
          <tr>
            <td colspan="4" class="pa-1 table">
              <span class="bold16">หมายเหตุ</span> แนบสำเนาเกียรติบัตร วุฒิบัตร
              หรือสำเนาเอกสารหลักฐานที่แสดงให้เห็นถึงการการอบรมสัมมนารวมทั้งศึกษาดูงานเพิ่มพูนความรู้ตนเองย้อนหลังไม่เกิน
              1 ปี นับถึงวันสุดท้ายของกำหนดการยื่นคำร้อง ขอย้ายประจำปี
              ประกอบด้วย หน้าคำสั่ง หน้าที่มีชื่อผู้ยื่นย้าย และ
              หน้าสุดท้ายของคำสั่งที่มีลายมือชื่อของผู้ลงนามในคำสั่ง ทั้งนี้
              โดยให้ผู้ประสงค์ขอย้ายและผู้อำนวยการสถานศึกษา
              หรือผู้รักษาการในตำแหน่งผู้อำนวยการสถานศึกษาต้นสังกัด
              ลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารทุกฉบับ
              <br />
              แนบเอกสารและหลักฐานประกอบการพิจารณาภาคผนวก ค
            </td>
          </tr>
        </table>

        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 10 -->

        <table width="100%" class="text_j">
          <tr>
            <td width="10%"></td>
            <td width="90%">
              7.1.4 การพัฒนาวิชาชีพ โดยได้รับแต่งตั้งเป็นคณะกรรมการ อนุกรรมการ
              คณะทำงาน หรือวิทยากร
            </td>
          </tr>
          <tr>
            <td colspan="2">
              นวัตกรรมสิ่งประดิษฐ์ การพัฒนาสื่อการเรียนการสอน งานวิจัย
            </td>
          </tr>
        </table>

        <table width="100%" class="text_j table">
          <tr>
            <td width="10%" class="bold16 table" align="center">
              ลำดับที่
            </td>
            <td width="50%" class="bold16 table" align="center">
              ชื่อคำสั่ง/โครงการ
            </td>
            <td class="bold16 table" align="center">เอกสารประกอบการพิจารณา</td>
          </tr>
          <tr v-for="(item, index) in personnel_experiencePros" :key="index">
            <td class="pa-1 table text-center text_al">{{ index + 1 }}</td>
            <td class="pa-1 table text_al">
              {{ item.course_name }} ปี{{ item.pexperienceyear_s }}
            </td>
            <td class="pa-1 table text_al">
              ภาคผนวก หน้า.....................
            </td>
          </tr>
          <tr>
            <td colspan="4" class="pa-1 table text_al">
              <span class="bold16">หมายเหตุ</span> สำเนาเกียรติบัตร วุฒิบัตร
              หรือสำเนาเอกสารหลักฐานที่แสดงให้เห็นถึงการได้รับแต่งตั้งเป็นคณะกรรมการ
              อนุกรรมการ คณะทำงาน หรือวิทยากร นวัตกรรมสิ่งประดิษฐ์
              การพัฒนาสื่อการเรียน การสอน งานวิจัย ย้อนหลังไม่เกิน 1 ปี
              นับถึงวันสุดท้ายของกำหนดการยื่นคำร้องขอย้ายประจำปี ประกอบด้วย
              หน้าคำสั่ง หน้าที่มีชื่อผู้ยื่นย้าย และ
              หน้าสุดท้ายของคำสั่งที่มีลายมือชื่อของผู้ลงนามในคำสั่ง ทั้งนี้
              โดยให้ผู้ประสงค์ขอย้ายและผู้อำนวยการสถานศึกษา
              หรือผู้รักษาการในตำแหน่งผู้อำนวยการสถานศึกษาต้นสังกัด
              ลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารทุกฉบับ
              <br />
              แนบเอกสารและหลักฐานประกอบการพิจารณาภาคผนวก ค
            </td>
          </tr>
        </table>


        <br />
        <table width="100%" align="center" class="text_j">
          <tr>
            <td width="50%"></td>
            <td align="center" width="50%">
              <div class="regular16">
                <span class="regular16"
                  >ขอรับรองว่าข้อมูลข้างต้นทั้งหมดเป็นความจริง</span
                >
                <br />
                <br />
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >ลงชื่อ...................................................ผู้ขอย้าย</span
                >
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="center regular16">
                (
                {{
                  transference_personnels.title_s +
                    transference_personnels.frist_name
                }}
                {{ transference_personnels.last_name }} )
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >ตำแหน่ง {{ transference_personnels.position_name }}</span
                >
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <br />
              <br />
            </td>
            <td></td>
          </tr>

          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >ลงชื่อ...............................................................</span
                >
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="center regular16">
                (....................................................................)
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >ตำแหน่ง.............................................................</span
                >
              </div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16">
                  วันที่
                  {{
                    date_today
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}</span
                >
              </div>
            </td>
          </tr>
        </table>


        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 5 -->

        <div class="bold16 text_j text_al">
          7.2
          การแสดงความรู้ความสามารถในการพัฒนาการจัดการเรียนการสอนตรงตามวิชาเอก/
          หรือตรงกับความต้องการของสถานศึกษาที่รับย้าย
        </div>

        <table width="100%" class="text_j table">
          <tr>
            <td width="50%" class="bold16 table" align="center">
              การแสดงความรู้ความสามารถ
            </td>
            <td class="bold16 table" align="center">เอกสารประกอบการพิจารณา</td>
          </tr>
          <tr>
            <td class="pa-1 text_al table">
              การเขียนประเด็นท้าทายในการพัฒนางานตามมาตรฐานวิทยฐานะที่ครองอยู่ ที่ตรงตามหลักสูตร
การจัดการเรียนการสอนในรายวิชาใดวิชาหนึ่งเพียง
วิชาเดียว ที่เคยทำการสอนในสถานศึกษาเดิม 
และสามารถนำไปสู่การปฏิบัติจริงในสถานศึกษา
ที่ขอย้าย ให้เกิดผลเป็นรูปธรรมได้ 

            </td>
            <td class="pa-1 text_al table">
              <div class="font-weight-bold">พิจารณาจาก</div>
              รูปแบบเป็นไปตามที่ ก.ค.ศ. กำหนด ในการเขียนข้อตกลง โดยพิจารณาจาก
              <br />
              <div class="font-weight-bold">1. ประเด็นท้าทาย</div>
              เป็นไปตามมาตรฐานวิทยฐานะของผู้ขอย้ายและมุ่งพัฒนาผู้เรียนเป็นหลัก
              <div class="font-weight-bold">2. วิธีดำเนินการ</div>
              มีความเป็นไปได้
              เป็นรูปธรรมที่สามารถทำให้ประเด็นท้าทายประสบความสำเร็จได้
              <div class="font-weight-bold">3. ผลที่คาดหวัง</div>
              เป็นผลที่เกิดขึ้นกับผู้เรียน /สถานศึกษา /ชุมชน / สถานประกอบการ 
              (ตามหลักเกณฑ์และวิธีการประเมินตำแหน่งและ
              วิทยฐานะข้าราชการครูและบุคลากรทางการศึกษา ตำแหน่งครู
              ตามหนังสือสำนักงาน ก.ค.ศ ที่ ศธ 0206.3/ว 9 ลงวันที่ 24 พฤษภาคม
              2564) 
              <br>
              ทั้งนี้
              ให้เขียนบรรยายแสดงความรู้ความสามารถในการพัฒนาการจัดการเรียนการสอนตรงตามวิชาเอก/
              หรือตรงกับความต้องการของสถานศึกษาที่รับย้ายเนื้อหาความยาวไม่เกิน 5
              หน้า กระดาษ A4 แบบอักษร TH SarabunIT๙ หรือ THSarabunPSK
              ขนาดตัวอักษร 16 พอยท์
              <div class="font-weight-bold">
                (กรรมการจะพิจารณาเอกสารเฉพาะหน้าที่ 1 – 5 เท่านั้น)
              </div>

              <div class="font-weight-bold">เกณฑ์การพิจารณาการให้คะแนน</div>
              ระดับดีมาก 18 – 20 คะแนน <br />
              ระดับดี 15 – 17 คะแนน <br />
              ระดับปานกลาง 12 - 14 คะแนน <br />
              ระดับน้อย 9 – 11 คะแนน <br />
              ระดับน้อยที่สุด 0 – 8 คะแนน<br />
            </td>
          </tr>
          <tr>
            <td colspan="3" class="pa-1 table">
              <span class="bold16">หมายเหตุ</span> รูปแบบเป็นไปตามที่ ก.ค.ศ.
              กำหนด ในการเขียนข้อตกลงที่เป็นประเด็นท้าทาย
              ตามวิทยฐานะของผู้ขอย้าย
              (ตามหลักเกณฑ์และวิธีการประเมินตำแหน่งและวิทยฐานะข้าราชการครูและบุคลากรทางการศึกษา
              ตำแหน่งครู ตามหนังสือสำนักงาน ก.ค.ศ ที่ ศธ 0206.3/ว 9 ลงวันที่ 24
              พฤษภาคม 2564) 
              <br>
              <div class="font-weight-bold"> <u> โดยเอกสารจัดทำในโปรแกรม Microsoft Office Word
              ความยาวไม่เกิน 5 หน้า กระดาษ A4 แบบอักษร TH SarabunIT๙ หรือ
              THSarabunPSK ขนาดตัวอักษร 16 พอยท์ และสแกนเป็นไฟล์ PDF ขนาดไม่เกิน
              5 MB ส่งให้งานบุคลากรของสถานศึกษาต้นสังกัด อัพโหลดไฟล์ในระบบ HRVEC
              และจัดทำเป็นรูปเล่มฉบับจริง จำนวน 1 ชุด</u> </div>
            </td>
          </tr>
        </table>
      

     

        <!-- New Page -->
      <!--   <p style="page-break-before: always;">&nbsp;</p> -->
        <!-- 11 -->

       <!--  <table width="100%" class="text_j">
          <tr>
            <td class="bold16 text-center">
              บัญชีเอกสารประกอบคำร้องขอย้ายข้าราชการครูและบุคลากรทางการศึกษา
            </td>
          </tr>
          <tr>
            <td class="bold16 text-center">
              ตำแหน่ง ครู สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
            </td>
          </tr>
          <tr>
            <td class="bold16 text-center">
              ครั้งที่
              {{
                transference_personnels.time_ss +
                  "/" +
                  transference_personnels.year_ss
              }}
            </td>
          </tr>
          <tr>
            <td class="bold16 text-center">
              ชื่อ
              {{
                transference_personnels.title_s +
                  transference_personnels.frist_name
              }}
              นามสกุล
              {{ transference_personnels.last_name }}
            </td>
          </tr>

          <tr>
            <td class="bold16 text-center">
              เลขที่ตำแหน่ง {{ transference_personnels.id_position }}
            </td>
          </tr>

          <tr>
            <td class="bold16 text-center">
              {{ transference_personnels.college_name }} จังหวัด{{
                transference_personnels.province_name
              }}
            </td>
          </tr>
        </table>

        <table width="100%" class="text_j table">
          <tr>
            <td width="15%" class="text-center table">องค์ประกอบ</td>
            <td width="50%" class="text-center table">รายการ</td>
            <td width="15%" class="text-center table">จำนวนหน้า</td>
            <td width="20%" class="text-center table">หมายเหตุ</td>
          </tr>
          <tr>
            <td class="text-center table">1.</td>
            <td class="table text_al pa-1">
              ความรู้ ความสามารถ ประสบการณ์ในวิชาเอกที่ย้ายไปดำรงตำแหน่ง
            </td>
            <td class="table"></td>
            <td class="table"></td>
          </tr>
          <tr>
            <td class="text-center table">2.</td>
            <td class="table text_al pa-1">
              ผลการปฏิบัติงานที่เกิดกับผู้เรียนและสถานศึกษาปัจจุบัน
            </td>
            <td class="table"></td>
            <td class="table"></td>
          </tr>
          <tr>
            <td class="text-center table">3.</td>
            <td class="table text_al pa-1">
              ระยะเวลาการปฏิบัติหน้าที่สอนในสถานศึกษาปัจจุบัน
            </td>
            <td class="table"></td>
            <td class="table"></td>
          </tr>
          <tr>
            <td class="text-center table">4.</td>
            <td class="table text_al pa-1">
              การรักษาวินัย คุณธรรม จริยธรรม และจรรยาบรรณวิชาชีพ
            </td>
            <td class="table"></td>
            <td class="table"></td>
          </tr>
          <tr>
            <td class="text-center table">5.</td>
            <td class="table text_al pa-1">การพัฒนาตนเองและพัฒนาวิชาชีพ</td>
            <td class="table"></td>
            <td class="table"></td>
          </tr>
          <tr>
            <td class="text-center table">6.</td>
            <td class="table text_al pa-1">
              สภาพความยากลำบากในการปฏิบัติงานในสถานศึกษาปัจจุบัน
            </td>
            <td class="table"></td>
            <td class="table"></td>
          </tr>
          <tr>
            <td class="text-center table">7.</td>
            <td class="table pa-1">ความอาวุโสตามหลักราชการ</td>
            <td class="table"></td>
            <td class="table"></td>
          </tr>
          <tr>
            <td class="table"></td>
            <td class="table text_al pa-1">
              รวม(กรณีใช้สำเนา ก.พ. ๗ หรือ กคศ. 16 หลายองค์ประกอบ อาจจะแทรก 1
              ฉบับแล้วใส่ตรงหมายเหตุว่า สำเนาก.พ. ๗ หรือ กคศ. 16 และ ทั้งนี้
              ให้ผู้ประสงค์ขอย้ายและผู้อำนวยการสถานศึกษาหรือผู้รักษาการในตำแหน่ง
              ลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารทุกฉบับ)
            </td>
            <td class="table"></td>
            <td class="table"></td>
          </tr>
        </table>

        <table class="text_j mt-2" width="100%" align="center">
          <tr>
            <td class="bold16">
              <p id="p1">
                ขอรับรองว่าเอกสารหลักฐานที่จัดส่งมานี้เป็นข้อมูลจริงถูกต้องจึงลงนามรับรองสำเนาเอกสารที่แนบมานี้
                ไว้เป็นหลักฐาน
              </p>
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="50%"></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >ลงชื่อ...................................................ผู้ขอย้าย</span
                >
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="center regular16">
                (
                {{
                  transference_personnels.title_s +
                    transference_personnels.frist_name
                }}
                {{ transference_personnels.last_name }} )
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >ตำแหน่ง {{ transference_personnels.position_name }}</span
                >
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <br />
              <br />
            </td>
            <td></td>
          </tr>

          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >ลงชื่อ...............................................................</span
                >
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="center regular16">
                ( {{ transference_personnels.collegeinfo_director }}
                )
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >ผู้อำนวยการ{{ transference_personnels.college_name }}</span
                >
              </div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >วัน...........เดือน................................ปี.................</span
                >
              </div>
            </td>
          </tr>
        </table> -->



          <!--  <div class="bold16 text_j">
          องค์ประกอบ/ตัวชี้วัดที่ 7 ความอาวุโสตามหลักราชการ
        </div>

        <table width="100%" class="text_j table">
          <tr>
            <td width="50%" class="bold16 table" align="center">
              วิทยฐานะ
            </td>
            <td class="bold16 table" align="center">เอกสารประกอบการพิจารณา</td>
          </tr>
          <tr>
            <td class="pa-1 table">
              (
              <span v-if="transference_personnels.rang_level === '4'">*</span> )
              วิทยฐานะเชี่ยวชาญขึ้นไป <br />
              (
              <span v-if="transference_personnels.rang_level === '3'">*</span> )
              วิทยฐานะชำนาญการพิเศษ <br />
              (
              <span v-if="transference_personnels.rang_level === '2'">*</span> )
              วิทยฐานะชำนาญการ <br />
              (
              <span
                v-if="
                  transference_personnels.rang_level === '' ||
                    transference_personnels.rang_level < '2'
                "
                >*</span
              >
              ) ไม่มีวิทยฐานะ
            </td>
            <td class="pa-1 table">
              สำเนา ก.พ. 7 หรือ ก.ค.ศ. 16 ที่เป็นปัจจุบัน
              โดยมีเจ้าหน้าที่งานทะเบียนประวัติลงลายมือชื่อรับรองสำเนาถูกต้อง
              <br />
              ภาคผนวก หน้า..................
            </td>
          </tr>
        </table> -->


      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    loading: true,
    dialogLoading: false,
    periods: [],
    transference_personnels: [],
    personnel_educations: [],
    personnel_dutys: [],
    personnel_awards: [],
    personnel_experiences: [],
    personnel_experiencePros: [],
    personnel_award_id: []
  }),

  async mounted() {
    await this.periodQuery();
    await this.transference_personnelsQueryAll();
    await this.personnel_educationsQueryAll();
    await this.personnel_awardsQueryAll();
    await this.personnel_dutysQueryAll();
    await this.personnel_experiencesQueryAll();
    await this.personnel_experiencesProQueryAll();
    await this.transference_personnels_award();
  },

  methods: {
    async transference_personnels_award() {
      let result = await this.$http.post("personnel_award.php", {
        ApiKey: this.ApiKey,
        id_pa: this.transference_personnels.tp_award
      });
      this.personnel_award_id = result.data;
    },

    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable: "1",
        period_type: "teacher"
      });
      this.periods = result_period.data;
    },

    async transference_personnelsQueryAll() {
      let result = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: this.url_result
      });
      this.transference_personnels = result.data;
    },

    async personnel_educationsQueryAll() {
      let result = await this.$http.post("personnel_education.php", {
        ApiKey: this.ApiKey,
        id_card: this.transference_personnels.id_card
      });
      this.personnel_educations = result.data;
    },
    /* professional */
    async personnel_dutysQueryAll() {
      let result = await this.$http.post("personnel_duty.php", {
        ApiKey: this.ApiKey,
        personnel_dutyIDCard: this.transference_personnels.id_card
      });
      this.personnel_dutys = result.data;
    },

    async personnel_awardsQueryAll() {
      let result = await this.$http.post("personnel_award.php", {
        ApiKey: this.ApiKey,
        personnel_awardid_card: this.transference_personnels.id_card,
        type_performanceCk: "Ok"
      });
      this.personnel_awards = result.data;
    },

    async personnel_experiencesQueryAll() {
      let result = await this.$http.post("personnel_experience.php", {
        ApiKey: this.ApiKey,
        pexperienceid_card: this.transference_personnels.id_card,
        pexperienceType: "self"
      });
      this.personnel_experiences = result.data;
    },

    async personnel_experiencesProQueryAll() {
      let result = await this.$http.post("personnel_experience.php", {
        ApiKey: this.ApiKey,
        pexperienceid_card: this.transference_personnels.id_card,
        pexperienceType: "professional"
      });
      this.personnel_experiencePros = result.data;
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-16'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-16," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    url_result() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    },
    /* อายุราชการในสาขาวิชาเอกที่ย้ายไปดำรงตำแหน่ง */
    age_position_year() {
      let age = this.transference_personnels.age_time;
      let split_year = age.split(" ");
      return split_year[0];
    },
    age_position_month() {
      let age = this.transference_personnels.age_time;
      let split_year = age.split(" ");
      let result;
      if (split_year >= 6) result = 1;
      else result = 0;
      return result;
    },
    age_time_result() {
      let month = this.age_position_month;
      let year = this.age_position_year;
      let result = parseInt(year) + parseInt(month);
      return result;
    },
    /* ประสบการณ์สอนในสถานศึกษาปัจจุบัน */

    age_appnow_year() {
      let age = this.transference_personnels.age_app_time;
      let split_year = age.split(" ");
      let result = parseInt(split_year[0] * 12);
      return result;
    },
    age_appnow_month() {
      let age = this.transference_personnels.age_app_time;
      let split_year = age.split(" ");
      return split_year[2];
    },
    age_appnow_day() {
      let age = this.transference_personnels.age_app_time;
      let split_year = age.split(" ");
      return split_year[4];
    },
    age_appCal() {
      let yearMonth = this.age_appnow_year;
      let month = this.age_appnow_month;
      let day = this.age_appnow_day;
      let result;
      if (day >= 15) {
        result = parseInt(yearMonth) + parseInt(month) + 1;
      } else {
        result = parseInt(yearMonth) + parseInt(month);
      }
      return result;
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    }
  },
  props: {
    source: String
  }
};
</script>
<style>
body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}
.text_l {
  border-collapse: collapse;
  text-align: left;
  text-justify: inter-word;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

.text_al {
  text-align: left;
  vertical-align: top;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}
#p1 {
  text-indent: 4em;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1.5cm;
  padding-right: 1.5cm;
  padding-bottom: 1.5cm;
  padding-left: 2cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 22pt;
  font-weight: bold;
  line-height: 1.5;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
        height: 247mm;
      }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1.5cm;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
          font-size: 18px;
          font-weight: bold;
        }
        .regular12 {
          font-size: 12px;
        }
        .blod12 {
          font-size: 12px;
          font-weight: bold;
        }
        .blackRegula8 {
          font-size: 8px;
        } */
  .noprint {
    display: none;
  }

  .gap-10 {
    width: 100%;
    height: 10px;
  }
  .gap-20 {
    width: 100%;
    height: 20px;
  }
  .gap-30 {
    width: 100%;
    height: 30px;
  }
}
</style>
